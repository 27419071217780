// ClipsSearchResultsPage.js

import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import './HomePage.css'; // Ensure this is imported so that .posts and .post-container styles apply

const ClipsSearchResultsPage = () => {
    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get('query');
    const { posts } = useContext(ClipsPostsContext);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Set the document title dynamically based on the search query
        if (searchQuery) {
            document.title = `Search results for '${searchQuery}'`;
        } else {
            document.title = 'Search results';
        }
    }, [searchQuery]);

    useEffect(() => {
        const filterPosts = () => {
            setIsLoading(true);
            setError(null);

            try {
                const queryLower = searchQuery ? searchQuery.toLowerCase() : '';
                const filteredResults = posts.filter(post =>
                    post.title.toLowerCase().includes(queryLower) ||
                    post.author.toLowerCase().includes(queryLower) ||
                    (post.description && post.description.toLowerCase().includes(queryLower))
                );

                setResults(filteredResults);
            } catch (error) {
                setError('Error filtering search results');
            } finally {
                setIsLoading(false);
            }
        };

        if (searchQuery) {
            filterPosts();
        }
    }, [searchQuery, posts]);

    const navigateToCategoryPosts = (category) => {
        navigate(`/categoryposts/${category}`);
        window.scrollTo(0, 0);
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="posts">
            <h2 className="search-results-heading" style={{ textAlign: 'center', marginBottom: '30px' }}>
                {searchQuery ? `Search results for "${searchQuery}"` : 'Search results'}
            </h2>
            {results.length === 0 ? (
                <p style={{ textAlign: 'center' }}>No results found for "{searchQuery}"</p>
            ) : (
                results.map(result => (
                    <div key={result.id} className="post-container">
                        <ClipsPost 
                            {...result}
                            navigateToCategoryPosts={navigateToCategoryPosts}
                        />
                    </div>
                ))
            )}
        </div>
    );
};

export default ClipsSearchResultsPage;


