// SharePost.js

import React from 'react';
import { Helmet } from 'react-helmet';
import {
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from 'next-share';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SharePost = ({ url, title, description, image }) => {
  const handleCopyLink = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(url).then(
      () => {
        alert('Link copied!');
      },
      () => {
        alert('Failed to copy');
      }
    );
  };

  const handleMessengerShare = () => {
    const messengerAppUrl = `fb-messenger://share?link=${encodeURIComponent(url)}`;
    window.location.href = messengerAppUrl;
    setTimeout(() => {
      window.location.href = url;
    }, 500);
  };

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
      </Helmet>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: 10 }}>
        {/* First Row */}
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%' }}>
          <IconButton onClick={handleMessengerShare}>
            <FacebookMessengerIcon size={32} round />
          </IconButton>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <WhatsappShareButton url={url} title={title} separator=":: ">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
        {/* Second Row */}
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          <EmailShareButton url={url} subject={title} body={description}>
            <EmailIcon size={32} round />
          </EmailShareButton>
          <Tooltip title="Copy Link">
            <IconButton onClick={handleCopyLink} style={{ padding: 0 }}>
              <ContentCopyIcon style={{ fontSize: '32px' }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SharePost;



