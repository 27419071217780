// ClipsMyPostsPage.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { FaUser, FaBell } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { getPostsByUserId } from '../utils/dbUtilities';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import './MyPostsPage.css';

function ClipsMyPostsPage() {
  const [myPosts, setMyPosts] = useState([]);
  const { currentUser } = useAuth();
  const [topMargin, setTopMargin] = useState('40px');
  const { deletePostById } = useContext(ClipsPostsContext);
  const navigate = useNavigate();
  const observerRef = useRef(null);
  const postsRefs = useRef({});

  useEffect(() => {
    window.scrollTo(0, 0);

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Handle intersection if needed
            console.log('Post visible:', entry.target.id);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    async function fetchMyPosts() {
      if (currentUser) {
        try {
          const userPosts = await getPostsByUserId(currentUser.uid, 'clipsPosts');
          const sortedUserPosts = userPosts
            .map(post => ({
              ...post,
              timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
            }))
            .sort((a, b) => b.timestamp - a.timestamp);
          setMyPosts(sortedUserPosts);
        } catch (error) {
          console.error("Error fetching user's posts:", error);
        }
      }
    }

    fetchMyPosts();
  }, [currentUser]);

  useEffect(() => {
    // Observe all posts
    myPosts.forEach(post => {
      const element = document.getElementById(post.id);
      if (element && observerRef.current) {
        postsRefs.current[post.id] = element;
        observerRef.current.observe(element);
      }
    });

    // Cleanup function
    return () => {
      if (observerRef.current) {
        Object.values(postsRefs.current).forEach(element => {
          if (element instanceof Element) {
            observerRef.current.unobserve(element);
          }
        });
      }
    };
  }, [myPosts]);

  useEffect(() => {
    const updateTopMargin = () => {
      if (window.innerWidth === 1264) {
        setTopMargin('140px');
      } else if (window.innerWidth <= 768) {
        setTopMargin('200px');
      } else {
        setTopMargin('120px');
      }
    };

    window.addEventListener('resize', updateTopMargin);
    updateTopMargin();

    return () => window.removeEventListener('resize', updateTopMargin);
  }, []);

  const handlePostDeletion = async (postId) => {
    try {
      await deletePostById(postId);
      setMyPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const navigateToContactList = () => {
    navigate('/view-contact-list');
  };

  return (
    <div className="my-posts-container" style={{ marginTop: topMargin }}>
      <h1 style={{ textAlign: 'center' }}><FaUser /> My Posts</h1>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button onClick={navigateToContactList} className="styled-button">
          <FaBell className="button-icon" /> View Email Sign Ups
        </button>
      </div>
      {myPosts.length > 0 ? (
        myPosts.map(post => (
          <div key={post.id} className="post-container" id={post.id}>
            <ClipsPost
              id={post.id}
              title={post.title || 'Untitled'}
              author={post.author || 'Unknown'}
              description={post.description || ''}
              categories={post.categories || []}
              imageUrl={post.imageUrl || ''}
              timestamp={post.timestamp || new Date()}
              lastEdited={post.lastEdited || null}
              city={post.city || ''}
              state={post.state || ''}
              country={post.country || ''}
              location={post.location || ''}
              link={post.link || ''}
              instagram={post.instagram || ''}
              twitter={post.twitter || ''}
              tiktok={post.tiktok || ''}
              youtube={post.youtube || ''}
              facebook={post.facebook || ''}
              bio={post.bio || ''}
              profileImageUrl={post.profileImageUrl || ''}
              userName={post.userName || ''}
              onDelete={() => handlePostDeletion(post.id)}
            />
          </div>
        ))
      ) : (
        <p>You don't have any posts.</p>
      )}
    </div>
  );
}

export default ClipsMyPostsPage;

