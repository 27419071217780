// Trending.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, List, ListItem, Typography, Fade, CircularProgress } from '@mui/material';
import { fetchOrUpdateRanks, getPostSummary } from '../utils/dbUtilities'; // Ensure getPostSummary is imported
import { IoTrendingUpOutline } from 'react-icons/io5';
import './Trending.css';

const Trending = () => {
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchTrendingPosts = useCallback(async () => {
    setLoading(true);
    try {
      const posts = await fetchOrUpdateRanks(); // Fetch top-ranked posts

      // Filter out deleted posts
      const validPosts = posts.filter(post => !post.deleted);

      const topPosts = validPosts
        .sort((a, b) => b.impressionCount - a.impressionCount) // Sort by impressions in descending order
        .slice(0, 20); // Get only the top 20

      // Fetch summaries for each post
      const postsWithSummaries = await Promise.all(
        topPosts.map(async (post) => {
          const summary = await getPostSummary(post.id);
          return {
            ...post,
            summary, // Use only the generated summary
          };
        })
      );

      setTrendingPosts(postsWithSummaries);
    } catch (error) {
      console.error('Error fetching trending posts:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTrendingPosts();
  }, [fetchTrendingPosts]);

  useEffect(() => {
    const handleStorage = () => {
      const savedPosts = localStorage.getItem('clickedPosts');
      if (savedPosts) {
        setClickedPosts(JSON.parse(savedPosts));
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  const handlePostClick = (postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
  };

  return (
    <div className="posts">
      <Fade in={true} timeout={800} unmountOnExit>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mt: 4,
            width: '100%',
            maxWidth: '100%',
            mb: 2,
            borderRadius: '16px',
            backgroundColor: '#fff',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: '100%', mb: 2, textAlign: 'left' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IoTrendingUpOutline
                size={32}
                style={{
                  color: '#1d1d1f',
                  marginRight: '8px',
                }}
              />
              <Box>
                <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f', lineHeight: 1.2 }}>
                  Trending Posts
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#86868b',
                    fontWeight: '500',
                    fontSize: '1rem',
                    letterSpacing: '0.01em',
                    marginLeft: '4px',
                  }}
                >
                  The People's Algorithm
                </Typography>
              </Box>
            </Box>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </Box>
          ) : (
            <List sx={{ width: '100%', padding: 0 }}>
              {trendingPosts.map((post, index) => (
                <Fade in={true} timeout={300} unmountOnExit key={post.id}>
                  <ListItem
                    component="div"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      padding: '16px 0',
                      borderRadius: '8px',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: '#86868b',
                        fontWeight: '700',
                        minWidth: '50px',
                        marginRight: '16px',
                        textAlign: 'center',
                      }}
                    >
                      {index + 1}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                        <Typography
                          variant="body1"
                          component="span"
                          onClick={() => handlePostClick(post.id)}
                          sx={{
                            color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            '&:hover': { textDecoration: 'underline' },
                            fontSize: '1.1rem',
                            marginBottom: '4px',
                          }}
                        >
                          {post.title}
                        </Typography>
                        <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
                          {post.city && post.state ? `(${post.city}, ${post.state})` : 
                            post.city ? `(${post.city})` : 
                            post.state ? `(${post.state})` : ''}
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}>
                        {post.summary}
                      </Typography>
                    </Box>
                  </ListItem>
                </Fade>
              ))}
            </List>
          )}
        </Paper>
      </Fade>
    </div>
  );
};

export default Trending;


