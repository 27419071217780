// ClipsUsersPosts.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { FaUser } from 'react-icons/fa';
import { getAllDataFromDB } from '../utils/dbUtilities';
import './HomePage.css'; // Ensure this is imported for consistent styling

function ClipsUsersPosts() {
    const [userPosts, setUserPosts] = useState([]);
    const { username } = useParams();

    useEffect(() => {
        // Set document title
        document.title = `${username}'s posts`;
        
        // Scroll to top when component mounts
        window.scrollTo(0, 0);
        
        async function fetchUserPosts() {
            try {
                const allPosts = await getAllDataFromDB('clipsPosts');
                const specificUserPosts = allPosts
                    .filter(post => post.author === username)
                    .map(post => ({
                        ...post,
                        timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp); // Newest to oldest
                setUserPosts(specificUserPosts);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        }
    
        fetchUserPosts();
    }, [username]);

    return (
        <div className="posts">
            <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>
                <FaUser /> Posts by {username}
            </h1>
            {userPosts.length === 0 ? (
                <div className="no-posts-message" style={{ textAlign: 'center' }}>
                    <p>No posts found for {username}</p>
                </div>
            ) : (
                userPosts.map(post => (
                    <div key={post.id} className="post-container">
                        <ClipsPost {...post} />
                    </div>
                ))
            )}
        </div>
    );
}

export default ClipsUsersPosts;

