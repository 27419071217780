// SignUpPage.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import './SignUpPage.css';
import { auth } from '../utils/firebaseConfig';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { saveDataToDB, getUserByUsername } from '../utils/dbUtilities';
import { useAuth } from '../contexts/AuthContext';

const SignUpPage = () => {
  const [firstName, setFirstName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const [mathChallenge, setMathChallenge] = useState({});
  const [mathAnswer, setMathAnswer] = useState('');
  const [error, setError] = useState(null);
  const [firstNameError, setFirstNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { setCurrentUser, setIsLoggedIn, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    generateMathChallenge();

    const handleFocus = (event) => {
      console.log("Input focused", event.target);
    };

    const handleBlur = (event) => {
      console.log("Input blurred", event.target);
    };

    const inputs = document.querySelectorAll('.signup-input');
    inputs.forEach(input => {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      });
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const generateMathChallenge = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setMathChallenge({ num1, num2 });
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit called with:', { firstName, username, email, password, mathAnswer });

    let isValid = true;

    if (!firstName.trim()) {
      setFirstNameError('Please enter your first name.');
      isValid = false;
    } else {
      setFirstNameError('');
    }

    if (!username.trim()) {
      setUsernameError('Please enter a username.');
      isValid = false;
    } else {
      setUsernameError('');
    }

    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (password.length < 6) {
      setPasswordError('Password should be at least 6 characters.');
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (parseInt(mathAnswer) !== mathChallenge.num1 + mathChallenge.num2) {
      console.log('Math challenge failed.');
      setError('Incorrect answer to math problem.');
      generateMathChallenge();
      return;
    }

    if (!isValid) {
      return;
    }

    try {
      console.log(`Checking if username "${username}" exists...`);
      const usernameExists = await getUserByUsername(username.toLowerCase());
      if (usernameExists) {
        console.log('Username check failed: Username exists.');
        setUsernameError("Username already taken. Try again.");
        return;
      }
    } catch (error) {
      console.error('Error checking username:', error);
      setError("Failed to check username availability.");
      return;
    }

    try {
      console.log('Creating user with email and password...');
      const userCredential = await createUserWithEmailAndPassword(auth, email.toLowerCase(), password);
      const firebaseUser = userCredential.user;
      console.log('User created:', firebaseUser);

      if (firebaseUser) {
        await updateProfile(firebaseUser, { displayName: username.toLowerCase() });
        await firebaseUser.reload();
        console.log('Firebase user profile updated.');

        const accountCreationTimestamp = Date.now();

        await saveDataToDB('users', {
          username: username.toLowerCase(),
          email: email.toLowerCase(),
          firstName: firstName,
          creationTime: accountCreationTimestamp,
          uid: firebaseUser.uid,
          blockedUsers: {}
        }, firebaseUser.uid);
        console.log(`SignUpPage: User data saved to database for ${firebaseUser.uid}`);

        setCurrentUser({ ...firebaseUser, displayName: username.toLowerCase() });
        setIsLoggedIn(true);
      }
    } catch (err) {
      console.error('Error during user creation:', err);
      if (err.code === "auth/email-already-in-use") {
        setEmailError("Email already registered. Try logging in.");
      } else if (err.code === "auth/weak-password") {
        setPasswordError("Password should be at least 6 characters.");
      } else {
        setError(err.message);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="signup-container">
      <div className="signup-header">Sign Up</div>
      {error && <p className="error-message">{error}</p>}
      <TextField
        fullWidth
        variant="outlined"
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        error={!!firstNameError}
        helperText={firstNameError}
        margin="normal"
        className="signup-input"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        error={!!usernameError}
        helperText={usernameError}
        margin="normal"
        className="signup-input"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
        margin="normal"
        className="signup-input"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!passwordError}
        helperText={passwordError}
        margin="normal"
        className="signup-input"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className="math-captcha">
        Solve this to prove you're not a robot: {mathChallenge.num1} + {mathChallenge.num2} = ?
      </div>
      <TextField
        fullWidth
        variant="outlined"
        type="text"
        value={mathAnswer}
        onChange={(e) => setMathAnswer(e.target.value)}
        error={error !== null && error.includes('math problem')}
        helperText={error && error.includes('math problem') ? error : ''}
        sx={{ '& .MuiInputBase-input': { textAlign: 'center' } }}
        margin="normal"
        className="signup-input"
      />
      <button className="signup-button" onClick={handleSubmit}>Sign Up</button>
      <div className="signup-links">
        <div className="signup-text">
          Have an account? <Link to="/login">Login</Link>
        </div>
      </div>
      <div className="disclaimer">
        Your HyroClipse and <a href="https://hyrovault.com" target="_blank" rel="noopener noreferrer">HyroVault</a> accounts share the same login credentials.
      </div>
    </div>
  );
};

export default SignUpPage;

