// contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../utils/firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

const AuthContext = createContext();

export const useAuth = () => {
   return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed. User:', user);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        console.log('Firebase document snapshot:', docSnap);
        if (docSnap.exists()) {
          console.log('Setting current user with additional Firestore data');
          setCurrentUser({ ...user, ...docSnap.data() }); // Combines Firestore data with auth data
        } else {
          console.log('Setting current user with basic auth data');
          setCurrentUser(user);
        }
        setIsLoggedIn(true);
      } else {
        console.log('User is not logged in');
        setCurrentUser(null);
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
  
    return () => {
      console.log('Unsubscribing from auth state change');
      unsubscribe();
    };
  }, []);  

  const logout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setCurrentUser(null);
      localStorage.setItem('loggedOut', 'true');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Add isAdmin computed value
  const isAdmin = currentUser?.role === 'admin';

  const value = {
    currentUser,
    isLoggedIn,
    setIsLoggedIn, 
    loading, 
    logout,
    isAdmin // Add isAdmin to the context value
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} 
    </AuthContext.Provider>
  );
};

export default AuthContext;
