// ExplorePage.js

import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import './ExplorePage.css';
import { getAllDataFromDB } from '../utils/dbUtilities';
import { useAuth } from '../contexts/AuthContext';

const ExploreItem = React.memo(({ post }) => (
    <div className="explore-item">
        <Link to={`/clipsposts/${post.id}`}>
            <img 
                src={post.imageUrl} 
                alt="explore thumbnail"
                className="explore-thumbnail"
                loading="lazy"
            />
        </Link>
    </div>
));

const ExplorePage = () => {
    const [posts, setPosts] = useState([]);
    const { currentUser } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0);

        async function fetchPosts() {
            try {
                let storedPosts = await getAllDataFromDB('clipsPosts');
                storedPosts = storedPosts
                    .filter(post => 
                        post.imageUrl && 
                        !post.imageUrl.includes('placeholder.com') && 
                        !post.deleted // Filter out deleted posts
                    )
                    .map(post => ({
                        ...post,
                        timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp);

                setPosts(storedPosts);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        }
        fetchPosts();
    }, []);

    if (!currentUser) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div className="explore-container">
            <div className="explore-grid">
                {posts.map((post) => (
                    <ExploreItem key={post.id} post={post} />
                ))}
            </div>
        </div>
    );
};

export default ExplorePage;
